$spaceamounts: (0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 50, 80, 100, 150);
$sides: (top, bottom, left, right, all, tb, space);

@each $space in $spaceamounts {
	@each $side in $sides {
		@if $side == 'all' {
			.m#{$space} {
				margin: #{$space}px !important;
			}

			.p#{$space} {
				padding: #{$space}px !important;
			}
		} @else if $side == 'tb' {
			.m#{$side}#{$space} {
				margin: #{$space}px 0 !important;
			}

			.p#{$side}#{$space} {
				padding: #{$space}px 0 !important;
			}
		} @else if $side == 'space' {
			.#{$side}#{$space} > *:not(:last-child) {
				margin-bottom: #{$space}px !important;
			}
		} @else {
			.m#{str-slice($side, 0, 1)}#{$space} {
				margin-#{$side}: #{$space}px !important;
			}

			.p#{str-slice($side, 0, 1)}#{$space} {
				padding-#{$side}: #{$space}px !important;
			}
		}
	}
}

.spacing {
	& > *:not(:last-child) {
		margin-bottom: 5px;
	}

	&-10 > *:not(:last-child) {
		margin-bottom: 10px;
	}

	&-15 > *:not(:last-child) {
		margin-bottom: 15px;
	}

	&-30 > *:not(:last-child) {
		margin-bottom: 30px;
	}
}

.width {
	&100 {
		width: 100%;
	}
}

.ma {
	margin-left: auto;
	margin-right: auto;
}

.ml-auto {
	margin-left: auto;
}

.mr-auto {
	margin-right: auto;
}
