$color-main: black;
$color-second: #B2b2b2;
$color-third: #1E1E1E;
$color-main-green: #54ae4a;
$white: #fff;
$black: #000;
$color-text-light: #a4abb3;
$color-text-dark: #2a2a2a;
$color-background: #ecf5fe;
$gradient-start: #2ab7c9;
$gradient-end: #0c99bd;
$color-light-grey: #acacac;
$status-color-danger: #ca5c54;
$status-color-warning: #e7af5f;
$status-color-success: #74b566;
$color-background-list: #eeefef;
$color-price:#C2964E;
$color-heart:#C2964E;

$colors: (
	color-main: $color-main,
	color-second: $color-second,
	white: $white,
	black: $black,
	color-text-light: $color-text-light,
	color-text-dark: $color-text-dark,
	color-background: $color-background,
	color-light-grey: $color-light-grey,
	color-background-list: $color-background-list,
	danger: $status-color-danger,
	warning: $status-color-warning,
	success: $status-color-success,
);

$main-spacing: 25px;
$spacing-responsive: 15px;
$text-font-size: 15px;
$heading-font-size: 17px;

$card-shadow: -6px 6px 0 2px transparentize($color-main, 0.95);
$card-header-size: 17px;
$card-title-size: 16px;
$card-subtitle-size: 14px;
$card-padding: $main-spacing - 5;
$card-spacing: $main-spacing;
$card-radius: 5px;
$card-filebox-color: #dbdbdb;
$card-gallery-padding: 15px;

$transition: all 0.2s ease;
