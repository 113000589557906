.sidenav ul {
  list-style-type: none;
  padding: 0;
  color: $color-second;
  .nav-link {
    color: $color-second;
    &:hover {
      color: $color-second;
    }
    &:visited {
      color: $color-second;
    }
    &:active {
      text-decoration: underline;
      color: black !important;
    }
    &-active {
      color: black !important;
    }
  }
}
