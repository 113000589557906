// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Bad+Script:400);
@import url(https://fonts.googleapis.com/css?family=Adamina&display=swap);
@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');

@font-face {
    font-family: 'GravurCondensed';
    src: url('./assets/fonts/GravurCondensed-Regular.woff2') format('woff2'),
        url('./assets/fonts/GravurCondensed-Regular.woff') format('woff'),
        url('./assets/fonts/GravurCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GravurCondensed';
    src: url('./assets/fonts/GravurCondensed-Bold.woff2') format('woff2'),
        url('./assets/fonts/GravurCondensed-Bold.woff') format('woff'),
        url('./assets/fonts/GravurCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import 'styles/variables';
@import 'styles/helpers/cursor';
@import 'styles/helpers/mediaqueries';
@import 'styles/mixins';
@import 'styles/helpers/animate';
@import 'styles/helpers/flex';
@import 'styles/helpers/grid';
@import 'styles/helpers/spacing';
@import 'styles/helpers/text';
@import 'styles/helpers/figure';

@import 'styles/gallery';
@import 'styles/item';
@import 'styles/sidenav';
@import 'styles/navbar';
@import 'styles/dropdown';
@import 'styles/background';
@import 'styles/contact';
@import 'styles/icofont';
@import 'styles/shareDialog';

body, #root {
  font-family: 'GravurCondensed';
  background-color: white;
}

.App {
  font-family: 'GravurCondensed';
  text-align: center;
}
.main {
  font-family: 'GravurCondensed';
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.usp {
  width: 100%;
  background-color: transparent;
  // text-transform: uppercase;
  font-family: 'GravurCondensed';
  font-size: calc(1em + 1vw);
  line-height: calc(0.7em + 1vw);
  text-align: center;
  color: black;
  text-transform: uppercase;
  span {
    font-size: calc(0.3em + 1vw);
  }
}
