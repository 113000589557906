
.background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // -webkit-transition: background-image 0.2s ease-in-out;
  // transition: background-image 2s ease-in-out;
  img {
    display: none;
  }
  &-nav {
    .navbar-nav {
      background-color: transparent;
      padding: 0 7px;
      border-radius: 10px;
    }
  }
}
