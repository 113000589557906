
.item-header {
  & > * {
    display: inline-block;
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    // max-height: calc(100vh - 230px);
}
