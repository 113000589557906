@import './variables';

$gray-sidebar: #24354c;
$default1:#5cccfa;
$default2:#111f39;

@media only screen and(max-width:767px){

    //Hidden and show class
    .desktop-hide {
      display: block;
    }

    .mobile-hide {
      display: none;
    }

    //Navbar
    //=====================***
    .navbar_wrap {
        .menu{
            width:10%;
            .nav_right{
                .icon{
                    position: relative;
                    button{
                        border: 1px solid #b6e2f5;
                        padding: 4px;
                        background: transparent;
                        border-radius: 2px;
                        &:focus{
                            outline: none;
                        }
                        span{
                            width: 24px;
                            height : 2px;
                            background: $white;
                            display: block;
                            margin: 4px 0;
                            &:nth-child(1){
                                margin-top:0;
                            }
                            &:nth-child(2){
                                width: 22px;
                                margin-left: 2px;
                            }
                            &:nth-child(3){
                                width: 20px;
                                margin-left: 4px;
                            }
                            &:nth-child(4){
                                width: 22px;
                                margin-left: 2px;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .mobile-menu{
                        position: absolute;
                        right: 0;
                        background: $default1;
                        text-align: left;
                        padding: 1rem;
                        min-width: 220px;
                        @include box-shadow;
                        li{
                            padding:1rem 0;
                            border-bottom: 1px solid #c6e6f3;
                            display: block;
                            a{
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.navbar_wrap {
  box-sizing: border-box;
  &.blue {
    background-color: #4584CA;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    width: 100%;
  }
  .logo {
    border-bottom: 2px solid black;
    img {
      margin: 20px 0;
      max-width: 100%;
    }
    color: $gray-sidebar;
    a {
      color: $gray-sidebar;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .icofont-before {
    height: 22px;
    width: 22px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .comming-soon {
    border-top: 1px solid black;
    display: block;
    text-align: center;
  }
  .desktop-menu {
    .active-link {
      text-decoration: underline;
    }
    .link-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      text-decoration: none;
      pointer-events: none;
    }
    display: flex;
    & > * {
      display: block;
      color: black;
      padding: 0 0.5rem;
      &.with-separator {
        border-left: 1px solid #000;
      }
      height: 20px;
    }
    .link-dropdown {
      position: absolute;
      z-index: 100;
      background-color: white;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: .25rem;
      padding: .5rem;
      a {
        display: block;
        color: inherit;
        text-decoration: none; /* no underline */
      }
    }
    .dropdown-submenu {
      .dropdown-submenu-wrapper {
        border-left: 1px solid black;
        & > *:before {
          content: '- '
        }
        & > .comming-soon:before {
          content: none;
        }
      }
      a {
        display: block;
        color: inherit;
        text-decoration: none; /* no underline */
      }
    }
  }
  .breadcrumb-item {
    color: rgba(0,0,0,.5)!important;
    text-decoration: none;
  }
  .menu{
    width: 70%;
    .nav_right{
      float: right;
      text-align: right;
      .icon {      }
      ul{
        li{
          padding: 0 2rem;
          display: inline-block;
          a{
            color: $default2;
            font-size:18px;
            text-transform: uppercase;
            &:hover{
              color: $default1;
              transition: 0.7s ease-in-out;
            }
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }
}
