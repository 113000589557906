.burger-menu {
  button {
    background-color: white !important;
    color: black !important;
    padding: 0 !important;
    box-shadow: none !important;
    border: none;
    &:hover {
      background-color: white;
      color: black;
    }
    &::after {
      content: '';
      margin: 0;
      border: none;
    }
  }
}
