
.contact {
  &-data {
    @include media-max-md {
      flex-direction: column;
    }
  }
  &-devider {
    border-left: 1px solid black;
  }
}
