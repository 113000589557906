// https://codepen.io/dudleystorey/pen/yqrhw
// https://w3bits.com/flexbox-masonry/

$columnWidth: 150px;
.gallery {
	// column-width: $columnWidth;
	// column-gap: 15px;
  width: 100%;
	// max-width: 1100px;
	margin: 0 auto;

  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  // gap: 1rem;
  &-item {
		box-sizing: border-box;
    // margin-bottom: 15px;
    // display: inline-block;
    // column-break-inside: avoid;

    box-shadow: none;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #DDDDDD;
			.card-body {
        background-color: rgba(255,255,255,0.6);
				display: flex;
        .item-price {
           display: initial;
         }
         .wishlist {
           display: flex !important;
         }
			}
    }
  }
  .card {
    overflow: hidden;
  }

	.wishlist {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
		padding: 5px 5px 0 0;
		z-index: 100000;
	}
  .card-body {
    flex-direction: column;
    bottom: 0;
		right: 0;
		top:0;
		position: absolute;
    width: 100%;
		padding: 5px 5px 5px 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		// color: #C2964E;
    color: black;
    font-family: 'Dosis', sans-serif;
		// font-family: 'Adamina', serif;
    font-size: 1.5em;
    padding: 0.75rem;
    i {
      color: black;
    }
    .wishlist:not(.in-wishlist) {
      display: none;
    }
    .item-price {
      flex: 0 1 50%;
      display: none;
    }
    .badge {
			font-size: initial;
			font-weight: initial;
      & + .badge {
        margin-left: 0.5rem;
      }
      border: 1px solid black !important;
			box-shadow: none;
      cursor: default !important;
      &:hover, &:focus {
        background-color: white;
      }
    }
  }
}
