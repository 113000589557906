.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon {
  width: 2.5em;
  height: 2.5em;
  fill: #222;
  stroke: #222;
}

.item-view {
  position: relative;
}

.item-view .prev-link {
  position: absolute;
  left: -30px;
  top: calc(50% - 30px);
}

.item-view .next-link {
  transform: rotate(180deg);
  position: absolute;
  right: 30px;
  top: calc(50% - 30px);
}

.wishlist--added {
  opacity: 0.5;
}
.Toastify__toast {
  font-family: 'GravurCondensed' !important;
}
